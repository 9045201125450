import Navbar from './Navbar';
import Footer from './Footer';
import Menu from './Menu';
import Register from './Register';
import Home from './Home';
import Login from './Login';
import Logout from  './Logout';
import Settings from './Settings';
import Contact from './Contact';
import Privacy from './Privacy';
import Projects from './Projects';
import Project from './Project';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { AuthProvider, RequireAuth } from "./Auth";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <div className="App">
          <Navbar />
          <Menu />
          <div className="content flex justify-center">
            <Routes>
              <Route element={<RequireAuth />}>
                <Route path='/home'  element={<Home />} />
                <Route path='/projects'  element={<Projects />} />
                <Route path='/settings'  element={<Settings />} />
                <Route path='/project'  element={<Project />} />
              </Route>

              <Route path='/login'  element={<Login />} />
              <Route path='/register'  element={<Register />} />
              <Route path='/logout'  element={<Logout />} />

              <Route path='/contact' element={<Contact />} />
              <Route path='/imprint' element={<Contact />} />
              <Route path='/privacy' element={<Privacy />} />

              <Route path="*" element={<Login />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
