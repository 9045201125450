const Contact = () => {
    return (  
        <div className="home">
        <div className="flex flex-col justify-center text-left w-full">
            <p>
Digital Express Post GmbH<br/>
HRB 276496<br/>
Managing Director: Daniel Ratke<br/>
            </p>
<br/>
            <p>
Contact:<br/>
Münchner Str. 25<br/>
85229 Markt Indersdorf<br/>
Germany
            </p>
<br/>
            <p>
info@overpassage.com
            </p>
        </div>
        </div>
    );
}

export default Contact;
