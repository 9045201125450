import { useState, useEffect } from "react";
import { IP } from './config.js';
import { useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'


const Project = (props) => {
    const [text_, setText] = useState("");
    const [blocks, setBlocks] = useState([]);
    const [n, setN] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const {state} = useLocation();

    function generate() {
      setDisabled(true);

      var interval = setInterval(() => {
        var form_ = new FormData();
        form_.append("id", state[0]);

        fetch(IP + "/blocks", {
          method: "POST",
          mode: "cors",
          body: form,
          credentials: "include",
        }).then((resp) => {
          resp.json().then((data) => {
            console.log(data);
            if (data[1].length > 0) {
              setBlocks(data[1]);
              setN(data[0]);
            }
          });
        });
      }, 1000);

      var form = new FormData();
      form.append("id", state[0]);
      form.append("text", text_);

      fetch(IP + "/generate", {
        method: "POST",
        mode: "cors",
        body: form,
        credentials: "include",
      }).then((resp) => {
        clearInterval(interval);
        resp.json().then((data) => {
          console.log(data);
          setBlocks(data);
          setDisabled(false);
        });
      });
    }

    useEffect(() => {
    }, []);

    return (
        <div className="home">
          <h2>Project: { state[2] }</h2>
            <div className="flex flex-col items-center">
                <ul className="menu menu-horizontal bg-base-300 rounded-box p-2">
                  <li className="bordered">
                    <a>
                        <img className="w-24" src="layout1.png"/>
                    </a>
                  </li>
                  <li>
                    <a>
                        <img className="w-24" src="layout2.png"/>
                    </a>
                  </li>
                  <li>
                    <a>
                        <img className="w-24" src="layout3.png"/>
                    </a>
                  </li>
                </ul>
                <textarea defaultValue={text_} onChange={(e) => setText(e.target.value)} placeholder="Describe your campaign." className="my-5 textarea textarea-bordered textarea-md w-full" rows="5"></textarea>

                <button className={`btn gap-2 ` + (disabled ? "btn-disabled" : "")} onClick={() => generate()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                  Generate
                </button>

                <progress className="progress progress-accent w-56" value={n} max="12"></progress>

            </div>

          <div className="divider">Results</div>

{ blocks.map(function (block, index) {
    return (
        <div className="card lg:card-side bg-base-300 shadow-xl my-5 ai-generated" key={index}>
{ block[0].length > 0 ?
          <div className="flex flex-wrap flex-row content-start items-start px-3 py-10">
            <figure><img src={block[0][0]} alt="Image"/></figure>
            <figure><img src={block[0][1]} alt="Image"/></figure>
            <div style={{flexBasis: '100%', height: 0}}></div>
            <figure><img src={block[0][2]} alt="Image"/></figure>
            <figure><img src={block[0][3]} alt="Image"/></figure>
          </div>
: <figure/>}
          <div className="card-body">
            <ReactMarkdown className="markdown" children={block[1]} escapeHtml={false} remarkPlugins={[remarkGfm]} />
          </div>
        </div>
    )
})}
        </div>
    ); 
}
 
export default Project;
