import { useState, useEffect } from "react";
import { IP } from './config.js';


const integrations = [
    {"title": "Canva", "url": "canva_logo.png", "desc": "Used to create beautiful layouts.", "enabled": false},
    {"title": "Figma", "url": "figma_logo.png", "desc": "Used to create beautiful designs.", "enabled": false},
    {"title": "DALL-E 2", "url": "openai_logo.png", "desc": "Used for image generation.", "enabled": true},
    {"title": "midjourney", "url": "midjourney_logo.png", "desc": "Used for image generation.", "enabled": false},
    {"title": "GPT-3.5", "url": "openai_logo.png", "desc": "Used for text generation.", "enabled": true},
    {"title": "Aleph Alpha", "url": "aleph_alpha_logo.png", "desc": "Used for text generation.", "enabled": false},
]


const Home = () => {
    useEffect(() => {

    }, []);

    return (
        <div className="home">

          <div className="stats shadow">
            <div className="stat">
              <div className="stat-title">Projects</div>
              <div className="stat-value">0 / 10</div>
              <div className="stat-desc"></div>
            </div>

            <button className="btn gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
              Project
            </button>
          </div>

          <div className="divider">Integrations</div>

{ integrations.map((integration) => {
          <div className="card w-96 bg-base-100 shadow-xl">
            <figure className="px-10 pt-10">
              <img src="/{integration.url}" alt="{integration.title}" className="rounded-xl" />
            </figure>
            <div className="card-body items-center text-center">
              <h2 className="card-title">{integration.title}</h2>
              <p>{ integration.desc }</p>
              <div className="card-actions">
{ integration.enabled ?
                <button className="btn btn-primary disabled">Enabled</button>
:
                <button className="btn btn-primary">Enable</button>
}
              </div>
            </div>
          </div>
})}

        </div>
    ); 
}
 
export default Home;
