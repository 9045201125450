import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IP } from './config.js';
import { loadStripe } from "@stripe/stripe-js";

const Settings = () => {
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = {password, password1};

        fetch(IP + '/settings', {
            method: 'POST',
            headers: { "Authorization" : 'Basic',
                       "Content-Type" : 'application/json',
                       "Access-Control-Allow-Origin": 'true'},
            mode: 'cors',
            credentials: 'include',
            body: JSON.stringify(user)
            }).then(res => {
              // console.log(res.status);
              res.json().then(data => {
                // console.log(data)
                alert('Password updated!')
              })
            });
    }

    const [showCopyAlert, setShowCopyAlert] = useState(false);
    const [stripe, setStripe] = useState();
    const [trialType, setTrialType] = useState("");

    const [api_key, setApi_key] = useState(() => {
        fetch(IP + '/home', {
            method: 'GET',
            headers: { "Authorization" : 'Basic',
                       "Content-Type" : 'text/html',
                       "Access-Control-Allow-Origin": 'true'},
            mode: 'cors',
            credentials: 'include',
        })
        .then(res => {
            return res.json();
        })
        .then(data => {
            setApi_key(data["key"])
            setTrialType(data["trial_type"])
        });
    });

    const handleEnable = (e) => {
        e.preventDefault();
        createCheckoutSession().then(function(data) {
          stripe
            .redirectToCheckout({
              sessionId: data.sessionId
            });
        });
    }

    const createCheckoutSession = () => {
      return fetch(IP + "/init_checkout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
      }).then(function(result) {
        return result.json();
      });
    }

    useEffect(() => {
      const stripePromise = loadStripe(
        "pk_live_51LH983ESQyOGrzeLgTW9gk6KSCWbxpa1DwtTLiMpWEv8jEy1xwqy1Y3peM9ee3wRxSFPAID3z2EQ8zLfV1kU0epa00F2F9Rk39"
      );

      stripePromise.then((ret) => {
        setStripe(ret);
      });

    }, []);

    return ( 
        <div className="settings" style={{width: "800px"}}>
        <br />

        <p>Account type: <kbd class="kbd kbd-sm">trial</kbd></p>
        <p></p>

        { trialType === 'trial' ?
            <>
                <h2>Need more requests?</h2>
                <button className="btn btn-primary" onClick={(e) => handleEnable(e)}>Upgrade key</button>
            </>
        : ""}
        <br /><br />
        <div>
          <h2>Change password</h2>
          <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-200 mb-20">
              <div className="card-body">
                <form onSubmit={ handleSubmit }>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Current Password</span>
                  </label>
                  <input placeholder="Password" className="input input-bordered w-full max-w-xs"
                      type="password" 
                      required
                  />
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">New Password</span>
                  </label>
                  <input placeholder="password" className="input input-bordered w-full max-w-xs"
                      type="password" 
                      required
                      value={ password }
                      onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Re-enter new Password</span>
                  </label>
                  <input placeholder="re-enter password" className="input input-bordered w-full max-w-xs"
                      type="password" 
                      required
                      value={ password1 }
                      onChange={(e) => setPassword1(e.target.value)}
                  />
                  <div className="form-control mt-6">
                  <button className="btn btn-primary">Submit</button>
                </div>
                  </div>
              </form>
              </div>
            </div>
          </div>
        </div>
        
        
     );
}
 
export default Settings;
