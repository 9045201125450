import { useState, useEffect } from "react";
import { IP } from './config.js';
import CreateProjectModal from './modals/create_project.js'
import { useNavigate } from 'react-router-dom';


const integrations = [
    {"title": "Canva", "url": "canva_logo.png", "desc": "Used to create beautiful layouts.", "enabled": false},
    {"title": "Figma", "url": "figma_logo.png", "desc": "Used to create beautiful designs.", "enabled": false},
    {"title": "DALL-E 2", "url": "openai_logo.png", "desc": "Used for image generation.", "enabled": true},
    {"title": "midjourney", "url": "midjourney_logo.png", "desc": "Used for image generation.", "enabled": false},
    {"title": "GPT-3.5", "url": "openai_logo.png", "desc": "Used for text generation.", "enabled": true},
    {"title": "Aleph Alpha", "url": "aleph_alpha_logo.png", "desc": "Used for text generation.", "enabled": false},
];


const Home = () => {
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
    const [projects, setProjects] = useState([]);

    const navigate = useNavigate();

    function handleCreateProjectModalClose() {
      setShowCreateProjectModal(false);
      window.location.reload();
    }

    function getProjects() {
      fetch(IP + "/projects", {
        method: "GET",
        mode: "cors",
        credentials: "include",
      }).then((resp) => {
        resp.json().then((data) => {
          console.log(data);
          setProjects(data)
        });
      });
    }

    useEffect(() => {
      getProjects();
    }, []);

    return (
        <div className="home">

          <CreateProjectModal
            showModal={showCreateProjectModal}
            handleModalClose={handleCreateProjectModalClose}
          ></CreateProjectModal>

          <div className="stats shadow">
            <div className="stat">
              <div className="stat-title">Projects</div>
              <div className="stat-value">{ projects.length } / 10</div>
              <div className="stat-desc"></div>
            </div>

            <button className="btn gap-2" onClick={() => setShowCreateProjectModal(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
              
            </button>
          </div>

{ projects.length > 0 ?
            <div className="overflow-x-auto my-10">
              <table className="table w-full">
                <thead>
                  <tr>
                    <th></th>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
{ projects.map(function (project, index) {
    return (
                  <tr key={index}>
                    <th>{ project[0] }</th>
                    <td>{ project[2] }</td>
                    <td>{ project[3] }</td>
                    <td className="w-24">
<button className="btn btn-sm btn-circle btn-outline" onClick={() => navigate('/project', { state: project })}>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-zap"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
</button>
<button className="btn btn-sm btn-circle btn-outline">
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
</button>
                    </td>
                  </tr>
    );
})}
                </tbody>
              </table>
            </div>
: ""}

          <div className="divider">Integrations</div>

        <div className="flex flex-row flex-wrap justify-center">
{ integrations.map(function (integration, index) {
    return (
          <div className="card w-48 bg-base-200 shadow-xl mx-5 my-5" key={index}>
            <figure className="px-10 pt-10 h-32">
              <img src={integration.url} alt={integration.title} className="rounded-xl" />
            </figure>
            <div className="card-body items-center text-center" style={{padding: '5px'}}>
              <h2 className="card-title">{integration.title}</h2>
              <p>{ integration.desc }</p>
              <div className="card-actions">
{ integration.enabled ?
                <button className="btn btn-secondary btn-disabled">Installed</button>
:
                <button className="btn btn-secondary">Enable</button>
}
              </div>
            </div>
          </div>
);
})}
          </div>

        </div>
    ); 
}
 
export default Home;
