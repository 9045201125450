import React, { useState } from "react";
import { IP } from "../config";


const CreateProjectModal = (props) => {
  const [title, setTitle] = useState("");
  const [type, setType] = useState("Report");
  const [textIntegration, setTextIntegration] = useState("GPT 3.5");
  const [imageIntegration, setImageIntegration] = useState("DALL-E 2");
  const [exportIntegration, setExportIntegration] = useState("DOC");

  function createProject(e) {
    var form = new FormData();
    form.append("title", title);
    form.append("type", type);
    form.append("text_integration", textIntegration);
    form.append("image_integration", imageIntegration);
    form.append("export_integration", exportIntegration);

    fetch(IP + "/projects", {
      method: "POST",
      mode: "cors",
      body: form,
      credentials: "include",
    }).then((resp) => {
      handleModalClose();
    });
  }

  function handleModalClose() {
    setTitle("");
    setType("report");
    setTextIntegration("GPT 3.5");
    setImageIntegration("DALL-E 2");
    setExportIntegration("DOC");
    props.handleModalClose();
  }

  return (
    <>
      <input
        type="checkbox"
        checked={props.showModal}
        id="add-project"
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box ">
          <h3 className="font-bold text-lg">Create a project</h3>
          <button
            htmlFor="add-project"
            className="btn btn-sm btn-circle btn-outline absolute right-2 top-2"
            onClick={handleModalClose}
          >
            ✕
          </button>

          <div className="form-control mt-3">
            <label className="input-group input-group-vertical">
              <span>Title</span>
              <input
                type="text"
                className="input input-bordered w-full"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </label>
          </div>

          <div className="form-control mt-3">
            <label className="input-group input-group-vertical">
              <span>Type</span>
              <select className="select select-bordered w-full">
                <option disabled selected>Select type...</option>
                <option>Report</option>
                <option>Blog Post</option>
                <option>E-book</option>
              </select>
            </label>
          </div>

          <div className="form-control mt-3">
            <label className="input-group input-group-vertical">
              <span>Text integration</span>
              <select className="select select-bordered w-full">
                <option disabled>Select text integration...</option>
                <option selected>GPT 3.5</option>
              </select>
            </label>
          </div>

          <div className="form-control mt-3">
            <label className="input-group input-group-vertical">
              <span>Image integration</span>
              <select className="select select-bordered w-full">
                <option disabled>Select image integration...</option>
                <option selected>DALL-E 2</option>
              </select>
            </label>
          </div>

          <div className="form-control mt-3">
            <label className="input-group input-group-vertical">
              <span>Export</span>
              <select className="select select-bordered w-full">
                <option disabled>Select export...</option>
                <option selected>DOC</option>
              </select>
            </label>
          </div>

          <div className="modal-action">
            <button
              className="btn"
              onClick={createProject}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProjectModal;
